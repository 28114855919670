/* eslint-disable default-case */
import React, {useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";

export default function Register(props) {
    const options = [
        {value: "", label: "User Type !"},
        {value: "DMEmployee", label: "DM Employee"},
        {value: "Client", label: "Client"},
    ];

    const navigate = useNavigate();

    // Register Form
    const [formRegister, setFormRegister] = useState({
        email: "",
        password: "",
        user_type: "",
    });
    //    default value datepicker
    const [birthDate, setBirthDate] = useState(null);

    // convert format date to string
    const formatDate = (date) => {
        let d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day, month, year].join("-");
    };

    const onChangeForm = (label, event) => {
        switch (label) {
            case "email":
                // email validation
                const email_validation = /\S+@\S+\.\S+/;
                if (email_validation.test(event.target.value)) {
                    setFormRegister({...formRegister, email: event.target.value});
                }
                break;
            case "password":
                setFormRegister({...formRegister, password: event.target.value});
                break;
            case "user_type":
                setFormRegister({...formRegister, user_type: event.target.value});
                break;
        }
    };

    //   Submit handler

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        console.log(formRegister);
        // Post to register API
        await axios
            .post("http://localhost:8888/api/v1.0.1/users/", formRegister)
            .then((response) => {
                // move to sign in page
                navigate("/?signin");

                // add successfully notif
                toast.success(response.data.detail);
                // reload page
                setTimeout(() => {
                    window.location.reload();
                }, 1000);

                console.log(response);
            })
            .catch((error) => {
                console.log(error);
                // add error notif
                toast.error(error.response.data.detail);
            });
    };

    return (
        <React.Fragment>
            <div>
                <h1 className="text-3xl font-bold text-center mb-4 cursor-pointer">
                    Create An Account
                </h1>
                <p className="w-80 text-center text-sm mb-8 font-semibold text-gray-700 tracking-wide cursor-pointer mx-auto">
                    Welcome to DMAfrica
                </p>
            </div>
            <form onSubmit={onSubmitHandler}>
                <div className="space-y-4">
                    <input
                        type="email"
                        placeholder="Email"
                        className="block text-sm py-3 px-4 rounded-lg w-full border outline-none focus:ring focus:outline-none focus:ring-red-600"
                        onChange={(event) => {
                            onChangeForm("email", event);
                        }}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        className="block text-sm py-3 px-4 rounded-lg w-full border outline-none focus:ring focus:outline-none focus:ring-red-600"
                        onChange={(event) => {
                            onChangeForm("password", event);
                        }}
                    />
                    <select
                        value={formRegister.user_type}
                        className="block text-sm py-3 px-4 rounded-lg w-full border outline-none focus:ring focus:outline-none focus:ring-red-600"
                        onChange={(event) => {
                            onChangeForm("user_type", event);
                        }}
                    >
                        {options.map((data) => {
                            if (data.value === "") {
                                return (
                                    <option key={data.label} value={data.value} disabled>
                                        {data.label}
                                    </option>
                                );
                            } else {
                                return (
                                    <option key={data.label} value={data.value}>
                                        {data.label}
                                    </option>
                                );
                            }
                        })}
                    </select>
                </div>
                <div className="text-center mt-6">
                    <button
                        type="submit"
                        className="py-3 w-64 text-xl text-white bg-red-600 rounded-2xl hover:bg-red-500 active:bg-red-600 outline-none"
                    >
                        Create Account
                    </button>
                    <p className="mt-4 text-sm">
                        Already have an account?{" "}
                        <Link
                            to="/?signin"
                            onClick={() => {
                                props.setPage("login");
                            }}
                        >
                            <span className="underline cursor-pointer">Sign In</span>
                        </Link>
                    </p>
                </div>
            </form>
        </React.Fragment>
    );
}

/* eslint-disable default-case */
import React, {useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import {data} from "autoprefixer";

export default function Login(props) {
    const [loginForm, setLoginform] = useState({
        username: "",
        password: "",
    });

    const onChangeForm = (label, event) => {
        switch (label) {
            case "username":
                setLoginform({...loginForm, username: event.target.value});
                break;
            case "password":
                setLoginform({...loginForm, password: event.target.value});
                break;
        }
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        console.log("This is loginform data")
        console.log(loginForm);
        console.log("Finished loginform")
        // call api login
        await axios
            .post("http://localhost:8000/login", data=loginForm)
            .then((response) => {
                console.log(response);
                // Save token to local storage
                localStorage.setItem("auth_token", response.data.result.access_token);
                localStorage.setItem(
                    "auth_token_type",
                    response.data.result.token_type
                );

                // add successfully notif
                toast.success(response.data.detail);
                // reload page after success login
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch((error) => {
                // add error notif

                console.log(error);
                toast.error(error.response.data.detail);
            });
    };

    return (
        <React.Fragment>
            <div>
                <h1 className="text-3xl font-bold text-center mb-4 cursor-pointer">
                    Welcome to DMAfrica
                </h1>
                <p className="w-80 text-center text-sm mb-8 font-semibold text-gray-700 tracking-wide cursor-pointer mx-auto">
                    Please login to your account!
                </p>
            </div>
            <form onSubmit={onSubmitHandler}>
                <div className="space-y-4">
                    <input
                        id="username"
                        type="text"
                        placeholder="Username"
                        className="block text-sm py-3 px-4 rounded-lg w-full border outline-none focus:ring focus:outline-none focus:ring-red-600"
                        onChange={(event) => {
                            onChangeForm("username", event);
                        }}
                    />
                    <input
                        id="password"
                        type="password"
                        placeholder="Password"
                        className="block text-sm py-3 px-4 rounded-lg w-full border outline-none focus:ring focus:outline-none focus:ring-red-600"
                        onChange={(event) => {
                            onChangeForm("password", event);
                        }}
                    />
                </div>
                <div className="text-center mt-6">
                    <button
                        type="submit"
                        className="py-3 w-64 text-xl text-white bg-red-600 rounded-2xl hover:bg-red-500 active:bg-red-600 outline-none"
                    >
                        Sign In
                    </button>
                    <p className="mt-4 text-sm">
                        You don't have an account?{" "}
                        <Link
                            to="/?register"
                            onClick={() => {
                                props.setPage("register");
                            }}
                        >
                            <span className="underline cursor-pointer">Register</span>
                        </Link>{" "}
                        or{" "}
                        <Link
                            to="/?forgot"
                            onClick={() => {
                                props.setPage("forgot");
                            }}
                        >
                            <span className="underline cursor-pointer">Forgot Password?</span>
                        </Link>
                    </p>
                </div>
            </form>
        </React.Fragment>
    );
}
